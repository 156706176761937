import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/page.tsx\",\"import\":\"Inter_Tight\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--mulish-font\",\"display\":\"swap\",\"preload\":true}],\"variableName\":\"inter_tight\"}");
;
import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/page.tsx\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--mono-font\",\"display\":\"swap\",\"preload\":true}],\"variableName\":\"roboto_modo\"}");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/app/[locale]/page-view-event.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/components/ai-agent/footer/footer.module.scss");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/components/ai-agent/hero/hero.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/ai-agent/images/images.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/ai-agent/info/info.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/components/layout/layout/main/main.module.scss");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/components/ai-agent/page-wrapper/page-wrapper.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/links/links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/logo/logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/profile/profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/wrapper/wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/popup/locale-selector-popup/locale-selector-popup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/social-links/social-links.tsx");
